
import Z3NANFT_ABI from "contracts/Z3NANFT.json";
import BRANDVERSE_AMBASSADOR_TOKEN_ABI from "contracts/BrandverseAmbassadorToken.json";

export enum ContractTypes {
  BRANDVERSE_AMBASSADOR_TOKEN = "BRANDVERSE_AMBASSADOR_TOKEN",
  Z3NA_NFT_CONTRACT = "Z3NA_NFT_CONTRACT",
}

interface ContractData {
  [key: string]: {
    address: string;
    abi: any;
  };
}

export const contractsData: ContractData = {
  BRANDVERSE_AMBASSADOR_TOKEN: {
    address: process.env.NEXT_PUBLIC_CONTRACT_BRANDVERSE_AMBASSADOR_TOKEN_ADDRESS || "",
    abi: BRANDVERSE_AMBASSADOR_TOKEN_ABI
  },
  Z3NA_NFT_CONTRACT: {
    address: process.env.NEXT_PUBLIC_CONTRACT_BRANDVERSE_Z3NA_ADDRESS || "",
    abi: Z3NANFT_ABI
  },
};

export const MINT_PRICE = 0.15;
