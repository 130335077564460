import React from "react";
import NextHead from "next/head";

interface HeadProps {
  title: string;
  description: string;
}

export const Head: React.FC<HeadProps> = (props) => {
  return (
    <NextHead>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />

      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://brandversenft.com" />
      <meta
        property="og:image"
        content="https://brandversenft.com/images/og.png"
      />
      <meta
        name="twitter:image"
        content="https://brandversenft.com/images/og.png"
      />

      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  );
};
