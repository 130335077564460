export enum T_SALE_STAGE {
  PHASE1 = "PHASE1",
  CLOSED = "CLOSED",
  PUBLIC = "PUBLIC",
}

export const INFURA_ID = process.env.NEXT_PUBLIC_INFURA_ID;
const NEXT_PUBLIC_VISIBLE_PAGE = process.env
  .NEXT_PUBLIC_VISIBLE_PAGE as T_SALE_STAGE;
export const SALE_STAGE: T_SALE_STAGE = Object.values(T_SALE_STAGE).includes(
  NEXT_PUBLIC_VISIBLE_PAGE
)
  ? NEXT_PUBLIC_VISIBLE_PAGE
  : T_SALE_STAGE.PUBLIC;
export const NETWORK = process.env.NEXT_PUBLIC_NETWORK || "eth";
export const CHAIN_ID =
  process.env.NEXT_PUBLIC_CHAIN_ID || (NETWORK === "rinkeby" ? 4 : 1);
export const METAMASK_DEEPLINK =
  "https://metamask.app.link/dapp/mint.z3na.xyz";
export const PRE_SALE_PHASE1_END_TIME = 1655193540000;

export const MAX_MINT_PRESALE =
  (process.env.NEXT_PRESALE_MAX_MINT &&
    parseInt(process.env.NEXT_PRESALE_MAX_MINT)) ||
  3;

export const MAX_MINT_PUBLIC =
  (process.env.NEXT_PUBLIC_MAX_MINT &&
    parseInt(process.env.NEXT_PUBLIC_MAX_MINT)) ||
  5;

export const EXTRA_PRESALE_MINTS_PER_AMBASSADOR_TOKEN = Number(process.env.NEXT_PUBLIC_EXTRA_PRESALE_MINTS_PER_AMBASSADOR_TOKEN || 4);
