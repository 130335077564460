import React from "react";
import ConnectButton from "components/ConnectButton/ConnectButton";
import { useWeb3Context } from "components/Layout";

export default function Navbar() {
  const { address } = useWeb3Context();

  return (
    <div>
      <nav className="flex items-center md:justify-between flex-wrap bg-black-500 sm:px-16 px-4 justify-center relative bottom-4">
        {address && (
          <div>
            <ConnectButton isHide />
          </div>
        )}
      </nav>
    </div>
  );
}
