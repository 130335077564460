import { useWeb3Context } from "components/Layout";
import Spinner from "components/Spinner/Spinner";
import { omit } from "lodash";
import React from "react";
import styles from "./Button.module.scss";

export type ButtonProps = {
  buttonType?: "ActionButton";
}

export default function Button(
  props: ButtonProps &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
) {
  const { isProcessing } = useWeb3Context();
  return (
    <button
      {...omit(props, "buttonType")}
      className={`${styles.DefaultButton} ${
        props.buttonType ? styles[props.buttonType] : ""
      } ${props.className ?? ""} disabled:opacity-50`}
    >
      {props.children}
      {props.disabled && isProcessing && <Spinner />}
    </button>
  );
}
